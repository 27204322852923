window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/booking-calendar'
# require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
require('riot-kit/lib/auth/forgot-password')

require './tags/loginbar.tag'
require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
require './tags/tags-input.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/pricetag.tag'
require './libs/slideshow.coffee'

require './tags/dropdown.tag'
require './tags/videomodal.tag'

require 'intersection-observer'

require 'details-polyfill'

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')

body = document.querySelector('body')
navbar = document.querySelector('.navbar-default')
obs = document.querySelector('#observer')
sb = document.querySelector('.slideblock')
bcb = document.querySelector('.breadcrumbbar')


# checkscroll = (e)=>
#   if e.type == "scroll"
#     console.log body.getBoundingClientRect().top
#     if Math.abs(body.getBoundingClientRect().top) > 100
#       body.classList.add('shortnav')
#     else
#       body.classList.remove('shortnav')

# ['scroll','load','resize'].forEach (type)->
#   window.addEventListener type,checkscroll


### INTERSECTION OBSERVER ###
### ===================== ###
console.log "calc",window.innerHeight
threshold = 1
options = {threshold: threshold}
observer = new IntersectionObserver (entry, observer)=>
  # console.log entry[0]
  # console.log body.getBoundingClientRect().height
  # console.log "winheight",parseInt(window.innerHeight+80)
  # console.log "bodyh",parseInt((body.getBoundingClientRect().height - 250)+80)
  console.log entry[0] , threshold, parseInt((body.getBoundingClientRect().height - 250)+80) , parseInt(window.innerHeight+80)
  if entry[0].intersectionRatio < threshold && parseInt((body.getBoundingClientRect().height - 250)+80) > parseInt(window.innerHeight+80)
    body.classList.add('shortnav')
    animating = true
  else
    body.classList.remove('shortnav')
  return
, options
observer.observe obs

if bcb && bcb.length > 0
  headeroptions = {threshold:1,rootMargin:'-80px'}
  headerobserver = new IntersectionObserver (entry,observer)=>
    # console.log "header"
    if entry[0].intersectionRatio < 1 && body.classList.contains('shortnav')
      bcb.classList.add('sticky')
    else
      bcb.classList.remove('sticky')
  , headeroptions
  headerobserver.observe sb

searchbutton = document.querySelector('li.searchlink a')
searchform = document.querySelector('form.searchform')
if searchbutton && searchform
  searchbutton.addEventListener 'click',(e)=>
    searchform.classList.toggle('active')

vidlinks = document.querySelectorAll('.figure-videolink')
if vidlinks
  [].map.call vidlinks, (vidlink)=>
    link = vidlink.querySelector('a[href]')
    if link
      vidlink.addEventListener 'click',(e)=>
        link.click()

navlinks = document.querySelectorAll('.navbar-nav > li:not(.searchlink)')
if navlinks && navlinks.length
  [].map.call navlinks, (nl)=>
    nl.querySelector('a').setAttribute('href','#')


# mobile nav stuff
# mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
# mo = new mmutationObserver (mutations)->
#   m = 0
#   # mutations.forEach (mutation)->
#   while m < mutations.length
#     mutation = mutations[m]
#     if mutation.type == "attributes" && mutation.target.className.match(/navshowing/)
#       [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach (subnav)->
#         subnav.addEventListener 'mouseover',(e)->
#           e.preventDefault()
#         subnav.addEventListener 'click',(e)->
#           if subnav.getAttribute('riot-tag') == "dropdown" && subnav.className.match(/open/)
#             subnav.querySelector('a').addEventListener 'click',(e)=>
#               window.location.href = subnav.querySelector('a').href
#             subnav.classList.remove('open')
#     m++

# mo.observe(document.querySelector('body'),{attributes:true})
